<template>
  <div class="project-detail-screen">
    <div :style="`left: -56px`" class="detail-title">
      <img class="icon-back" @click="backToList" src="/img/project/icon-back.png" alt="" />
      {{ title }}
    </div>
    <div class="screenTitle">
      <p class="title">{{$t('screen.largeScreen')}}</p>
      <div v-if="type != 'add'">
        <p class="icon" @click="handleClick">
          <img class="icon" :src="'/img/project/icon-edit.png'" alt="" />
        </p>
      </div>
      <div v-else>
        <p class="icon" @click="cancleClick">
          <img class="icon" :src="'/img/project/icon-close.png'" alt="" />
        </p>
      </div>
    </div>
    <!-- 编辑大屏信息 -->
    <div v-if="type == 'add'" class="edit-box">
      <div class="from-box">
        <!-- 主标题 -->
        <div class="name">
          <p class="name-title title">
            <span><a class="required">*</a>{{$t('screen.mainTitle')}}</span>
          </p>
          <div class="name-input">
            <div class="name-content content">
              <el-input class="uav-input" v-model="formDetail.screenTitle" 
                :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
            </div>
          </div>
        </div>
        <!-- 副标题 -->
        <div class="name">
          <p class="name-title title">
            <span>{{$t('screen.subtitle')}}</span>
          </p>
          <div class="name-input">
            <div class="name-content content">
              <el-input class="uav-input" v-model="formDetail.screenSubTitle" 
                :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
            </div>
          </div>
        </div>
        <!-- 大屏模板 -->
        <div class="name">
          <p class="name-title title">
            <span><a class="required">*</a>{{$t('screen.largeScreenTemplate')}}</span>
          </p>
          <div class="name-input" @click="openSeletedScreen">
            <div class="name-content content">
                <el-tooltip class="item" :content="formDetail.screenVO ? formDetail.screenVO.name : ''" 
                  effect="dark" placement="top">
                  <div class="screen_input">{{formDetail.screenVO ? formDetail.screenVO.name : ''}}</div>
                </el-tooltip>
            </div>
          </div>
        </div>
        <!-- 林草大屏附带信息 -->
        <div v-if="formDetail.screenVO && formDetail.screenVO.route === 'forest'">
          <div class="name">
            <p class="name-title title">
              <span>{{$t('screen.teamLeader')}}</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-input class="uav-input" v-model="onDutyInfo.leader" 
                  :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span>{{$t('screen.dutyOfficer')}}</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-input class="uav-input" v-model="onDutyInfo.director" 
                  :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span>{{$t('screen.dutyDispatch')}}</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-input class="uav-input" v-model="onDutyInfo.dispatcher" 
                  :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span>{{$t('screen.dutySectionChief')}}</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-input class="uav-input" v-model="onDutyInfo.sectionChief" 
                  :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span>{{$t('screen.dutyCommunication')}}</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-input class="uav-input" v-model="onDutyInfo.correspondent" 
                  :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span>{{$t('screen.dutyDriver')}}</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-input class="uav-input" v-model="onDutyInfo.driver" 
                  :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span>{{$t('screen.dutyPersonnel')}}</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-input class="uav-input" v-model="onDutyInfo.people" 
                  :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span>{{$t('screen.communicationPhone')}}</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-input class="uav-input" v-model="onDutyInfo.communicationTelephone" 
                  :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="finish">
        <el-button class="finish-btn" :loading="saveLoading" type="primary" @click="save">{{$t('save')}}</el-button>
      </div>
    </div>
    <!-- 查看大屏信息 -->
    <div v-else class="show-box">
      <div class="show-box-top">
        <!-- 主标题 -->
        <div class="name">
          <p class="name-title title">
            <span>{{$t('screen.mainTitle')}}</span>
          </p>
          <div class="name-input">
            <div class="name-content title">
              <span>{{ projectInfo.screenTitle }}</span>
            </div>
          </div>
        </div>
        <!-- 副标题 -->
        <div class="name">
          <p class="name-title title">
            <span>{{$t('screen.subtitle')}}</span>
          </p>
          <div class="name-input">
            <div class="name-content title">
              <span>{{ projectInfo.screenSubTitle }}</span>
            </div>
          </div>
        </div>
        <!-- 大屏模板 -->
        <div class="name">
          <p class="name-title title">
            <span>{{$t('screen.largeScreenTemplate')}}</span>
          </p>
          <div class="name-input">
            <div class="name-content title">
              <span>{{projectInfo.screenVO ? projectInfo.screenVO.name : ''}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="finish">
        <el-button class="finish-btn" type="primary" 
          @click="viewScreen">{{$t('screen.viewLargeScreen')}}</el-button>
      </div>
    </div>
    <!-- 选择大屏模板 -->
    <ScreenTemplate :show.sync="showTemplate" :screenVOData="formDetail.screenVO" @changeTemplate="changeTemplate" />
  </div>
</template>

<script>
import {getProjectDetail, screenTitleSet} from "@/api/forestGrass/index";
import ScreenTemplate from '@/components/screenTemplate/index';

export default {
  name: "FGkUav",
  components: {ScreenTemplate},
  data() {
    return {
      projectInfo: {}, // 项目信息
      type: 'view', // 显示或者编辑状态 view 、 add
      onDutyInfo: {},// 林草项目附属信息
      formDetail: {}, // 编辑表单
      saveLoading: false, // 保存等待
      showTemplate: false // 是否显示大屏模板弹窗
    };
  },
  computed: {
    title() {
      if (this.$route.query && this.$route.query.projectName) {
        return this.$route.query.projectName;
      }
      if (this.detailInfo && this.detailInfo.projectName) {
        return this.detailInfo.projectName;
      }
      return '';
    }
  },
  watch: {
    projectInfo() {
      this.initOnDutyInfo();
    }
  },
  created() {
    this.getProjectDetail();
  },
  methods: {
    // 返回
    backToList() {
      this.$router.push('/forest-grass/FG-Inspection');
    },
    // 获取大屏基础信息
    getProjectDetail() {
      const id = this.$route.query.projectId;
      if (!id) return;
      return new Promise((resolve, reject) => {
        getProjectDetail({id}).then((res) => {
          if (res.data.code === 200) {
            this.projectInfo = res.data.data || {};
            this.initOnDutyInfo();
            resolve();
          }
        }).catch(() => {
          reject();
        });
      });
    },
    // 获取林草其他的大屏信息
    initOnDutyInfo() {
      this.onDutyInfo = (this.projectInfo && this.projectInfo.onDuty)? this.projectInfo.onDuty: {};
    },
    // 编辑大屏信息
    handleClick() {
      this.formDetail = JSON.parse(JSON.stringify(this.projectInfo));
      this.type = "add";
    },
    // 关闭编辑
    cancleClick() {
      this.type = 'view';
    },
    // 保存大屏信息
    save() {
      if (!this.formDetail.screenVO || !this.formDetail.screenVO.id) {
        return this.$message.warning('请选择要展示的大屏模板!');
      }
      this.projectInfo.projectId = this.projectInfo.id;
      let data = {
        onDuty: this.onDutyInfo,
        projectId: this.formDetail.id,
        screenSubTitle: this.formDetail.screenSubTitle,
        screenTitle: this.formDetail.screenTitle,
        screenId: this.formDetail.screenVO.id
      };
      this.saveLoading = true;
      screenTitleSet(data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.getProjectDetail().then(()=>{
            this.type="view";
            this.$emit('save');
          });
        }
      }).catch((err) => {
        this.$message.error(err.msg);
      }).finally(() => {
        this.saveLoading = false;
      });
    },
    // 查看大屏
    viewScreen() {
      if (!this.projectInfo.screenVO || !this.projectInfo.screenVO.id) {
        return this.$message.warning('请选择要展示的大屏模板!');
      }
      this.$router.push({
        path: '/screen-datav',
        query: {
          projectType: 'forest',
          flyId: this.projectInfo.flyProjectID,
          projectId: this.projectInfo.id,
        }
      });
    },
    // 打开大屏模板选择
    openSeletedScreen() {
      this.showTemplate = true;
    },
    // 选择大屏模板后回调
    changeTemplate(data) {
      this.formDetail.screenVO = data;
    }
  }
};
</script>

<style lang="scss">
.project-detail-screen {
  position: relative;
  height: calc(100% - 20px);
  box-sizing: border-box;
  width: 290px;
  padding: 10px;
  .detail-title {
    position: absolute;
    top: -49px;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #ffffff;
    padding-top: 9px;
    padding-bottom: 11px;
    display: flex;
    align-items: center;
    .icon-back {
      margin-right: 4px;
    }
  }
  .screenTitle {
    display: flex;
    align-items: center;
    height: 46px;
  }

  .edit-box {
    display: flex;
    flex-direction: column;
    height: calc(100% - 47px);
    overflow: hidden;
    .from-box {
      flex: 1;
      overflow: auto;
    }
  }

  .show-box {
    display: flex;
    flex-direction: column;
    height: calc(100% - 47px);
    .show-box-top {
      flex: 1;
      overflow: auto;
      .file-name-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .file-name {
          color: #aaa;
          max-width: calc(100% - 60px);
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .el-radio {
          padding-top: 3px;
        }
      }
    }
    .finish {
      text-align: center;
      margin-left: 10px;
      height: 36px;
      &-btn {
        width: 217px;
        height: 36px;
        line-height: 36px;
        background: #265edd;
        border-radius: 3px;
        font-size: 14px;
        font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
        font-weight: bold;
        color: #ffffff;
        padding: 0;
        border: none;
      }
    }
  }

  .title {
    flex: 1;
    size: 16px;
    color: #FFFFFF;
  }

  .name-content {
    color: #aaa;
    .screen_input {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      padding: 0 5px;
      color: #ffffff;
      cursor: pointer;
      border: 1px solid #535353;
      background: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }

  p.title {
    padding: 15px 5px 10px 0px;
  }

  .icon-container {
    flex-shrink: 0;
  }

  .icon {
    width: 15px;
    /* 根据需要调整图标的宽度 */
    height: 15px;
    /* 根据需要调整图标的高度 */
  }

  .required {
    color: red;
    margin-right: 5px;
  }

  .finish {
    margin-top: 22px;
    text-align: center;
    margin-left: 10px;
    height: 36px;
    &-btn {
      width: 217px;
      height: 36px;
      line-height: 36px;
      background: #265edd;
      border-radius: 3px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #ffffff;
      padding: 0;
      border: none;
    }
  }

  .el-input {
    width: 100%;
    .el-input__inner {
      background-color: #030303;
      border: 1px solid #535353;
      color: #FFFFFF;
      text-align: left;
    }
  }
}
</style>
